import React from "react";
import PageWrapper from "../components/PageWrapper";
import { ROUTE_LINKS } from "../utils/routes";
import SEO from "../components/SEO";
import { HUBSPOT_FORMS } from "../utils/constants";
import Button from "../components/Button";
import EmbedModal from "../components/EmbedModal";

const ContactUs: React.FC = () => {
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [dialogData, setDialogData] = React.useState({
    embedUrl: "",
    header: "",
  });

  const closeDialog = () => {
    setDialogVisible(false);
  };

  return (
    <PageWrapper path={ROUTE_LINKS.contactUs}>
      <SEO pageUrl={ROUTE_LINKS.contactUs} />
      <main className="max-w-6xl md:mx-auto">
        <div className="flex flex-col justify-center h-48 p-3 text-center">
          <h2 className="font-bold text-large leading-48">Get In</h2>
          <h2 className="-mt-3 font-bold heading text-large">Touch</h2>
        </div>
        <div className="mt-4 flex flex-col sm:flex-row justify-center items-center">
          <Button
            type="button"
            className="py-4 px-6 text-xl rounded-lg"
            onClick={() => {
              setDialogData({
                embedUrl: HUBSPOT_FORMS.managerLearnMore,
                header: "Learn More",
              });
              setDialogVisible(true);
            }}
          >
            Property Manager
          </Button>
          <br />
          <Button
            type="button"
            className="py-4 px-20 sm:ml-8 text-xl rounded-lg"
            onClick={() => {
              setDialogData({
                embedUrl: HUBSPOT_FORMS.renterLearnMore,
                header: "Learn More",
              });
              setDialogVisible(true);
            }}
          >
            Renter
          </Button>
        </div>
        <EmbedModal
          data={dialogData}
          isVisible={dialogVisible}
          setIsVisible={closeDialog}
        />
      </main>
    </PageWrapper>
  );
};

export default ContactUs;
